<template>
    <!-- login-area -->
    <section class="login-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="login-right row">
              <div class="login-form custom-form col-lg-6 col-md-8 mx-auto">
                <!-- logo -->
                <div
                  class="logo-login wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <a href="/"
                    ><img
                        width="245px"
                      class="img-fulid"
                      src="../assets/img/logo.svg"
                      alt="logo"
                  /></a>
                </div>
                <!-- logo -->
                  <div
                    class="login-title wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <h2>Dealer is being restricted.
                    Contact the administrator to unlock access</h2>
                  </div>
                  <p v-if="$route.query.message" class="alert alert-success">
                    {{ message }}
                  </p>
                  <p></p>
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- login-area -->
  </template>
  
  <script>
  import VueDaval from "vue-daval";
  export default {
    name: "DealerDeactivatedError",
    beforeCreate: function () {
      var auth = this.$storage.get("auth");
      if (auth) {
        this.$router.push("/dashboard");
      }
    },
    mixins: [VueDaval],
    data() {
      return {
        message : ""
      };
    },
    vdRules: {
      
    },
    created() {
      
    },
    methods: {
      
    },
    mounted() {
      if (this.$route.query.message) {
        this.message = this.$route.query.message;
      }
    },
  };
  </script>